<template>
   <base-section id="404" class="accent" style="height: calc(100vh - 152px);">
      <base-heading title="404" class="white--text" />

      <base-img :src="require('@/assets/logo.svg')" class="mx-auto mb-12" contain height="350" width="250" />

      <div class="text-center">
         <base-btn color="primary" class="mx-1" :to="{ name: 'Home' }">
            Primary
         </base-btn>
         <base-btn color="secondary" class="mx-1" :to="{ name: 'Home' }">
            Secondary
         </base-btn>
         <base-btn color="accent" class="mx-1" :to="{ name: 'Home' }">
            Accent
         </base-btn>
      </div>
   </base-section>
</template>

<script>
export default {
   name: 'FourOhFour',

   provide: {
      heading: { align: 'center' },
   },
}
</script>
